.col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.divButton {
    display: flex;
    gap: 20px;
    padding: 0px 0px 30px 0px;
}

.divButtonGroup {
    padding: 0px 40px 15px 0px;
    gap: 20px;
    justify-content: flex-end;
    display: flex;
}

.divPadding {
    padding: 20px;
}

.formItem {
    display: flex;
    align-content: center;
}
