@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap');
.prompt {
  font-family: 'Prompt', sans-serif !important;
}
.prompt body {
  font-family: 'Prompt', sans-serif !important;
}
.prompt .ant-col.ant-col-6.gutter-row.css-dev-only-do-not-override-osc5ob {
  font-family: 'Prompt', sans-serif !important;
}
.prompt button.ant-btn.css-dev-only-do-not-override-osc5ob.ant-btn-default.button {
  font-family: 'Prompt', sans-serif !important;
}
.prompt thead.ant-table-thead {
  font-family: 'Prompt', sans-serif !important;
}
.prompt tbody.ant-table-tbody {
  font-family: 'Prompt', sans-serif !important;
}
.prompt :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-col'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-col'] {
  font-family: 'Prompt', sans-serif !important;
}
.prompt code {
  font-family: 'Prompt', sans-serif !important;
}
.prompt :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-layout'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-layout'] {
  font-family: 'Prompt', sans-serif !important;
}
.prompt :where(.css-dev-only-do-not-override-sk7ap8).ant-menu {
  font-family: 'Prompt', sans-serif !important;
}
.prompt :where(.css-dev-only-do-not-override-sk7ap8).ant-modal {
  font-family: 'Prompt', sans-serif !important;
}
.prompt :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-btn'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-btn'] {
  font-family: 'Prompt', sans-serif !important;
}
.prompt :where(.css-osc5ob)[class^='ant-col'],
:where(.css-osc5ob)[class*=' ant-col'] {
  font-family: 'Prompt', sans-serif !important;
}
.prompt :where(.css-osc5ob)[class^='ant-btn'],
:where(.css-osc5ob)[class*=' ant-btn'] {
  font-family: 'Prompt', sans-serif !important;
}
