.steps-modal-color .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-tail::after {
  background-color: #faccad;
  height: 2px;
}
.steps-modal-color
  .ant-steps-item-finish
  .ant-steps-item-container
  .ant-steps-item-icon
  .ant-steps-icon {
  color: var(--green);
}
.steps-modal-color
  .ant-steps-item-finish
  .ant-steps-item-container
  .ant-steps-item-icon
  .ant-steps-icon
  svg {
  width: 30px;
  height: 30px;
}
