.ant-steps-item-container {
  cursor: pointer !important;
}

.ant-steps-item-wait .ant-steps-item-container {
  cursor: default !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--primary-dark) !important;
  border-color: var(--primary-dark) !important;
}

.ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: var(--primary-dark) !important;
}

.ant-steps-item-wait
  .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: var(--primary-dark) !important;
}

.ant-steps-item-container[role='button']:hover .ant-steps-item-title {
  color: var(--primary-dark) !important;
}

.ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: var(--primary-dark) !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--primary-dark) !important;
}

.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--primary-dark) !important;
}
