.background-log {
  background-color: transparent;
  /* background-image: url('../assets/img/background.jpg'); */
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
/* style={{
  backgroundImage: `url(${backgroundPage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}} */
