.appFooter {
  display: flex;
  background-color: #3c1c1f !important;
  color: var(--white) !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  justify-content: space-between;
  align-items: flex-end;
}

.leftFooter {
  text-align: left;
}

.rightFooter {
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  color: #dddddd !important;
  max-width: 70%;
}

.addressFooter {
  font-size: 12px;
  font-weight: 400;
  color: #dddddd !important;
}
