@import 'https://cdn.jsdelivr.net/gh/lazywasabi/thai-web-fonts@7/fonts/NotoSansThaiLooped/NotoSansThaiLooped.css';
.forn-noto-sans-thai-looped {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped body {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped .ant-col.ant-col-6.gutter-row.css-dev-only-do-not-override-osc5ob {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped
  button.ant-btn.css-dev-only-do-not-override-osc5ob.ant-btn-default.button {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped thead.ant-table-thead {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped tbody.ant-table-tbody {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-col'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-col'] {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped code {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-layout'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-layout'] {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped :where(.css-dev-only-do-not-override-sk7ap8).ant-menu {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped :where(.css-dev-only-do-not-override-sk7ap8).ant-modal {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-btn'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-btn'] {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped :where(.css-osc5ob)[class^='ant-col'],
:where(.css-osc5ob)[class*=' ant-col'] {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
.forn-noto-sans-thai-looped :where(.css-osc5ob)[class^='ant-btn'],
:where(.css-osc5ob)[class*=' ant-btn'] {
  font-family: 'Noto Sans Thai Looped', sans-serif !important;
}
