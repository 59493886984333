.ant-form-item {
  margin-bottom: 0;
}
.link-a {
  color: #1677ff;
  cursor: pointer;
}
.link-a:hover {
  color: #69b1ff;
}

.ant-form-item-has-error .thaiDatePicker {
  border-color: #ff4d4f !important;
}
