.ellipsis-outlined-css svg {
  width: 40px;
  height: 40px;
}
.file-outlined-css svg {
  width: 24px;
  height: 24px;
}
span.anticon.anticon-info-circle {
  display: none;
}
.plusCircle-outlined-css svg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.plusCircle-outlined-disabled svg {
  width: 40px;
  height: 40px;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  box-shadow: none;
}
/* .anticon svg {
  color: #d55100;
} */
