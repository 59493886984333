@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Thai:wght@100;200;300;400;500&display=swap');
.font-Noto-serif {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif body {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif .ant-col.ant-col-6.gutter-row.css-dev-only-do-not-override-osc5ob {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif button.ant-btn.css-dev-only-do-not-override-osc5ob.ant-btn-default.button {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif thead.ant-table-thead {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif tbody.ant-table-tbody {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-col'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-col'] {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif code {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-layout'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-layout'] {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif :where(.css-dev-only-do-not-override-sk7ap8).ant-menu {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif :where(.css-dev-only-do-not-override-sk7ap8).ant-modal {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-btn'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-btn'] {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif :where(.css-osc5ob)[class^='ant-col'],
:where(.css-osc5ob)[class*=' ant-col'] {
  font-family: 'Noto Serif Thai', serif !important;
}
.font-Noto-serif :where(.css-osc5ob)[class^='ant-btn'],
:where(.css-osc5ob)[class*=' ant-btn'] {
  font-family: 'Noto Serif Thai', serif !important;
}
