.loading {
  display: flex;
  width: 100%;
  background: rgb(0 0 0 / 26%);
  height: 100vh;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
