li.ant-menu-item.ant-menu-item-selected {
  background-color: #faccad !important;
  color: #56282d !important;
  border-color: #faccad !important;
}
.ant-menu-item:focus {
  background-color: #faccad !important;
  color: #56282d !important;
  border-color: #faccad !important;
}
.ant-menu-submenu-title:hover {
  background-color: #faccad !important;
  color: #56282d !important;
}
.ant-menu-item .ant-menu-item-selected {
  color: #56282d !important;
}
.item-memu-css {
  font-size: 16px;
}
.item-memu-sub {
  font-size: 14px;
}
.ant-menu-submenu-title:active {
  background-color: #faccad !important;
  color: #56282d !important;
}
.ant-menu-submenu-title {
  color: #fff !important;
}
li.ant-menu-item:hover {
  background-color: #faccad !important;
  color: #56282d !important;
  border-color: #faccad !important;
}
.ant-menu-item:active {
  background-color: #faccad !important;
  color: #56282d !important;
  border-color: #faccad !important;
}
.ant-menu-submenu-title li.ant-menu-item :hover {
  color: #56282d !important;
}
