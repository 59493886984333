.layout {
  min-height: 100vh;

  /* background-image: url('../../assets/img/background.jpg'); */
  background-color: rgb(0 0 0 / 50%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.header {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.divHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 20px 40px 20px;
  gap: 10px;
  /* flex: 0.8; */
}
.divHeaderText {
  font-weight: 700;
  color: #fff;
  font-size: 20px;
}
.menu {
  background-color: #56282d;
  color: #fff;
  flex: 2;
  font-size: 16px;
}
.logoutDiv {
  align-items: flex-end;
  justify-content: center;
  display: flex;
  gap: 10px;
  flex: 3;

  font-size: 16px;
}
.buttonLogout {
  background-color: rgb(86, 40, 45);
  border-color: rgb(86, 40, 45);
  color: #fff;
  font-size: 16px;
}
.buttonLogout:hover {
  background-color: rgb(86, 40, 45) !important;
  border-color: rgb(86, 40, 45) !important;
  color: #fff !important;
}
.divChildren {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.contentSite {
  color: rgb(86, 40, 45);
  background-color: rgb(250, 204, 173);
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
}
.headerText {
  font-size: 20px;
}
.divDirection {
  display: flex;
  flex-direction: column;
}
.divDirection {
  display: flex;
  flex-direction: column;
}
.divUser {
  display: flex;
  align-items: center;
}

@media only screen and (max-height: 768px) {
  .contentSite {
    color: rgb(86, 40, 45);
    background-color: rgb(250, 204, 173);
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    padding: 5px 30px;
    display: flex;
    justify-content: space-between;
  }
}
