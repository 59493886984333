.tableHeader {
  margin-bottom: 5px;
  font-weight: bold;
}

.contractHeader {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.2rem;
}
