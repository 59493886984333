.col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.divButton {
  display: flex;
  gap: 20px;
  padding: 0px 0px 30px 0px;
}
.divButtonGroup {
  gap: 20px;
  justify-content: center;
  display: flex;
}
.divPadding {
  padding: 20px;
}
.divButtonGroupModal {
  display: flex;
  justify-content: center;
  gap: 10px;
}
