.custominput {
  padding: 7px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  font-size: var(--font-size-md);
}

.custominput::placeholder {
  color: #bfbfbf;
}

.custominput:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.custominput:focus {
  outline: none;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
  transition: all 0.1s;
}

.custominput:hover {
  outline: none;
  /* color: rgba(0, 0, 0, 0.85); */
  border: 1px solid #d9d9d9;
  box-shadow: none;
}
