@import url('https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap');
.k2d {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d body {
  font-family: 'K2D', sans-serif !important;
}
.k2d .ant-col.ant-col-6.gutter-row.css-dev-only-do-not-override-osc5ob {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d button.ant-btn.css-dev-only-do-not-override-osc5ob.ant-btn-default.button {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d thead.ant-table-thead {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d tbody.ant-table-tbody {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-col'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-col'] {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d code {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-layout'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-layout'] {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d :where(.css-dev-only-do-not-override-sk7ap8).ant-menu {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d :where(.css-dev-only-do-not-override-sk7ap8).ant-modal {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-btn'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-btn'] {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d :where(.css-osc5ob)[class^='ant-col'],
:where(.css-osc5ob)[class*=' ant-col'] {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
.k2d :where(.css-osc5ob)[class^='ant-btn'],
:where(.css-osc5ob)[class*=' ant-btn'] {
  font-family: 'K2D', sans-serif !important;
  font-size: 16px;
}
