.position-login {
  background-color: #ffffff;
  height: 560px;
  width: 40%;
  box-shadow: 8px 8px 20px rgb(0 0 0 / 10%);
  border-radius: 47px;
}
.padding-conten-main {
  padding: 20px 0px;
  overflow-y: scroll;
  overflow-x: clip;
  max-height: 800px;
}

.main-login {
  padding: 40px 70px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.img-login {
  width: 100px;
  height: 150px;
}
.header-title-login {
  font-size: 20px;
  font-weight: 700;
}
.content-site {
  color: rgb(86, 40, 45);
  background-color: rgb(250, 204, 173);
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
}
.headerTitle-layout {
  background-color: rgb(139 34 38);
  height: 47px;
  display: flex;
  align-items: center;
  padding: 20px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  color: var(--white);
}
.form-manage-rao-row-col {
  padding: 40px 60px 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.padding-conten {
  display: flex;
  gap: 20px;
  padding: 0px 0px 30px 0px;
}

@media only screen and (max-width: 1024px) {
  .position-login {
    height: 560px;
  }
}
@media only screen and (max-width: 768px) {
  .position-login {
    height: 540px;
    width: 60%;
  }
}
@media only screen and (max-width: 425px) {
  .position-login {
    height: 600px;
    width: 90%;
  }
}

@media only screen and (max-height: 768px) {
  .padding-conten {
    padding: 0px 0px 10px;
  }
  .img-login {
    width: 90px;
    height: 90px;
  }
  .header-title-login {
    font-size: 18px;
  }
  .main-login {
    padding: 10px 50px;
  }
  .position-login {
    height: 460px;
  }
  .content-site {
    color: rgb(86, 40, 45);
    background-color: rgb(250, 204, 173);
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    padding: 5px 30px;
    display: flex;
    justify-content: space-between;
  }

  .form-manage-rao-row-col {
    padding: 15px 60px 10px 0px;
  }
  .button {
    height: 30px;
  }

  /* .logout-button-site {
    height: 70%;
  } */
  .padding-conten-main {
    padding: 15px;
  }
}
@media only screen and (min-width: 1440px) {
  .position-login {
    width: auto;
    height: 560px;
  }
}
