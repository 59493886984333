@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap');
.font-bai-jamjuree {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree body {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree .ant-col.ant-col-6.gutter-row.css-dev-only-do-not-override-osc5ob {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree button.ant-btn.css-dev-only-do-not-override-osc5ob.ant-btn-default.button {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree thead.ant-table-thead {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree tbody.ant-table-tbody {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-col'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-col'] {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree code {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-layout'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-layout'] {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree :where(.css-dev-only-do-not-override-sk7ap8).ant-menu {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree :where(.css-dev-only-do-not-override-sk7ap8).ant-modal {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-btn'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-btn'] {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree :where(.css-osc5ob)[class^='ant-col'],
:where(.css-osc5ob)[class*=' ant-col'] {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
.font-bai-jamjuree :where(.css-osc5ob)[class^='ant-btn'],
:where(.css-osc5ob)[class*=' ant-btn'] {
  font-family: 'Bai Jamjuree', sans-serif !important;
}
