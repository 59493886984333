.modal-alert .ant-modal-content {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  padding: 10px 24px;
}
.modal-alert .ant-modal-body .ant-modal-confirm-body-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.modal-alert .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body {
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
}
.modal-alert
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-body
  .anticon-check-circle {
  font-size: 100px;
  margin-inline-end: 0px !important;
}
.modal-alert
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-body
  .anticon-close-circle {
  font-size: 100px;
  margin-inline-end: 0px !important;
}
.modal-alert
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-body
  .anticon-exclamation-circle {
  font-size: 100px;
  margin-inline-end: 0px !important;
}
.modal-details .ant-modal-confirm-content {
  flex: 1;
}
.modal-alert-text-header-success {
  color: #5cb631;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}
.modal-alert-text-header-error {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;

  color: #c1272d;
}
.modal-alert-text-sub-error {
  color: #3b3b3b;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
}
.modal-alert-text-sub-success {
  color: #3b3b3b;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
}
