.sider-navbar {
  background: #fff !important;
}
.sider-navbar .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.item-sider {
  padding: 0;
}
.item-sider:where(
    .css-dev-only-do-not-override-9ntgx0
  ).ant-menu-light.ant-menu-inline
  .ant-menu-item {
  color: #858585;
}
.item-sider .ant-menu-item-group .ant-menu-item-group-title {
  /* border-bottom: 1px solid #c6c6c6; */
  padding: 8px 0px;
}
