@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap');
@import './styles/font-noto-thai.css';
@import './styles/font-bai-jamjuree.css';
@import './styles/font-sara-ban.css';
@import './styles/forn-noto-sans-thai-looped.css';
@import './styles/font-promt.css';
@import './styles/k2d.css';
body {
  margin: 0;
  font-family: 'K2D', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-col'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-col'] {
  font-family: 'K2D', sans-serif;
}
code {
  font-family: 'K2D', sans-serif;
}
:where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-layout'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-layout'] {
  font-family: 'K2D', sans-serif;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-menu {
  font-family: 'K2D', sans-serif;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-modal {
  font-family: 'K2D', sans-serif;
}
:where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-btn'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-btn'] {
  font-family: 'K2D', sans-serif;
}
:where(.css-dev-only-do-not-override-osc5ob).ant-layout .ant-layout-content {
  background-color: #fff;
}
:where(.css-osc5ob).ant-layout .ant-layout-content {
  background-color: #fff;
}
