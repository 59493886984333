.ant-btn-default:hover {
  color: var(--primary-dark) !important;
  border-color: var(--primary-dark) !important;
}

.ant-btn-default:disabled {
  cursor: not-allowed !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none !important;
}

.ant-btn-primary:disabled.ant-btn-primary:hover {
  cursor: not-allowed !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none !important;
}
