.button {
  border-radius: 47px;
  height: 40px;
  background-color: #faccad;
  color: #56282d;
  box-sizing: border-box;
  box-shadow: none;
  font-size: 16px;
}
.button:hover {
  background-color: #faccad !important;
  color: #56282d !important;
  border-color: #faccad !important;
}
.button:disabled {
  cursor: not-allowed !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 768px) {
  .button-login-submit {
    height: 100%;
    width: 60%;
  }
  .button-login-off {
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .button-login-submit {
    width: 70%;
  }
}
