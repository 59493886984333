@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');
.font-sara-ban {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban body {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban .ant-col.ant-col-6.gutter-row.css-dev-only-do-not-override-osc5ob {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban button.ant-btn.css-dev-only-do-not-override-osc5ob.ant-btn-default.button {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban thead.ant-table-thead {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban tbody.ant-table-tbody {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-col'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-col'] {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban code {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-layout'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-layout'] {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban :where(.css-dev-only-do-not-override-sk7ap8).ant-menu {
  font-family: 'Sarabun', sans-serif !important;
}
.font-Noto-serif :where(.css-dev-only-do-not-override-sk7ap8).ant-modal {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-btn'],
:where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-btn'] {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban :where(.css-osc5ob)[class^='ant-col'],
:where(.css-osc5ob)[class*=' ant-col'] {
  font-family: 'Sarabun', sans-serif !important;
}
.font-sara-ban :where(.css-osc5ob)[class^='ant-btn'],
:where(.css-osc5ob)[class*=' ant-btn'] {
  font-family: 'Sarabun', sans-serif !important;
}
