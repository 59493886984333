.ant-modal-confirm-btns {
  /* display: none; */
}
.title-modal {
  font-weight: 600;
  font-size: 20px;
}
.modal-layout .ant-modal-content .ant-modal-header {
  margin-bottom: 20px;
}
.content-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.header-content-modal {
  background-color: rgb(139 34 38);
  height: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  font-weight: 700;
  color: #fff;
}
.row-content-col-modal {
  padding-bottom: 20px;
}
@media only screen and (max-width: 425px) {
  .modal-code .ant-modal-content {
    padding: 40px 60px;
  }
}
