.primary {
  border-radius: 47px;
  background-color: var(--primary);
  color: var(--primary-dark);
  box-sizing: border-box;
  box-shadow: none;
}

.primary:hover {
  background-color: var(--primary-dark) !important;
  color: var(--white) !important;
}
