.layout-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content-box {
  padding: 15px 20px;
}
.tabs-scroll {
  /* overflow-y: scroll;
  overflow-x: clip;
  max-height: 800px; */
}
