.input-group:hover {
  border-color: #d7d7d7;
  box-shadow: none;
}
.input-group:focus {
  border-color: #d7d7d7;
  box-shadow: none;
}
.inputText {
  height: 40px;
  border-radius: 10px;
}
.inputText:hover {
  border: 1px solid #d9d9d9 !important;
}
.inputText:focus {
  border: 1px solid #d9d9d9 !important;
}
