.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.plr-15 {
  padding: 0 15px;
}

.no-right-radius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.no-left-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}
